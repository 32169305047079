import '../css/Question.css';
import QuestionListItem from './QuestionListItem';
import QuestionCheckItem from './QuestionCheckItem';
import QuestionTools from './QuestionTools';
//import HistoryDropdown from './HistoryDropdown';
import { useCookies } from 'react-cookie';

import afi_logo from '../images/afi_logo.jpg';
import af_logo from '../images/af_logo.png';

import React, { useEffect, useState } from "react";
//import { useForm, FormProvider } from "react-hook-form";

import {
    useNavigate
} from "react-router-dom";

import { useParams } from "react-router";

const jsonVersion = "1.0.0"

function Question() {

    let params = useParams();

    //Set up cookies
    const [cookies, setCookie] = useCookies(['user'])

    //Load data from the json file
    const [data, setData] = useState([]);
    const [qid, setQid] = useState({ "current": 1, "previous": 1 });
    const [questionHistory, setQuestionHistory] = useState([]);

    const [answer, setAnswer] = useState(0);

    const navigate = useNavigate();

    const reset = () => {
        document.cookie = "question_history=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.location = "/"
    };

    let count = 0;
    let numberCheck = []
    const counter = (data, cid) => {
        data.forEach(function(obj) {
            if(Number(cid) === Number(obj.id)) {
                if(typeof(obj.answers[0]) !== "undefined") {
                    if(numberCheck.indexOf(obj.answers[0].next) === -1) { //make sure we don't get infinite loops
                        numberCheck.push(obj.answers[0].next)
                        count++;
                        counter(data, obj.answers[0].next);
                    }
                }
            }
        });
    }



    const updateAnswer = (answer, back = false) => {
        if (back === false && answer !== "") {
            setAnswer(answer)
            console.log("Answer History", questionHistory)
        } else {
            console.log("back");
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        //await submitForm(e.target);
        if (answer !== 0) {
            setQid({ "current": answer, "previous": params.question });
            setQuestionHistory([...questionHistory, qid.current]);
            setCookie('question_history', [...new Set([...questionHistory, answer])], { path: '/' });
            setAnswer(0)
            navigate("/question/" + answer, { replace: true });
        }
    }

    const getData = () => {
        fetch('/data.json?v='+jsonVersion
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson)
            });
    }

    useEffect(() => {
        getData()
        console.log('Starting')
        setQid({ "current": params.question, "previous": qid })

        if (cookies.question_history) {
            setQuestionHistory(cookies.question_history);
            //history.push('/' + cookies.question_history[cookies.question_history.length - 1])
        }
        // eslint-disable-next-line
    }, [])

    const q = data.filter(function (question) {
        // eslint-disable-next-line
        return question.id == qid.current;
    });

    //Set the question counter
    counter(data, qid.current);
    console.log('count',count)

    return (
        <><div className="logos">
        <img src={af_logo} width="200" alt="Agrifutures Logo" />
        <img src={afi_logo} width="100" alt="Australian Farm Institute Logo" />
        </div>
        <div>
            <h1>CARBON OPPORTUNITIES FOR AUSTRALIAN FARMERS</h1>
            <div className="flex-content">
                <div className='flex-item'>
                    <h2>{data && data.length > 0 && q[0].question}</h2>
                    <div className="questions-remaining">
                        {count !== 0 && q[0].type !== "finish" ? <p>Depending on your answer there could be up to <span>{count}</span> questions remaining.</p> : ''}
                    </div>
                    <div id="content">
                        {data && data.length > 0 && <p dangerouslySetInnerHTML={{ __html: q[0].content }}></p>}

                        {data && data.length > 0 && q[0].video_id != null ? <div class="video"><iframe src={"https://www.youtube.com/embed/"+q[0].video_id} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div> : null}

                        {data && data.length > 0 && q[0].type === "finish" && q[0].table.length > 0 ? <div id="benefits_table"><h3>Summary Table</h3><table>{q[0].table.map((item, row_num) => <tr>{q[0].table[row_num].map((item, col_num) => row_num === 0 ? <th>{ item }</th> : <td>{ item }</td> ) }</tr>)}</table></div>: null}

                        {data && data.length > 0 && q[0].additional_info !== '' && q[0].type === "finish"  ? <div id="additional_info"><h3>Key considerations and next steps:</h3><p dangerouslySetInnerHTML={{ __html: data && data.length > 0 && q[0].additional_info }}></p></div> : null}

                        {data && data.length > 0 && q[0].type === "finish" && q[0].table.length > 0 && q[0].answers.length > 0 ?
                        <h3 className="finish">Would you like to explore the next carbon opportunity?</h3>
                            : ''}

                        {data && data.length > 0 && q[0].answers.length > 0 ?
                            <form onSubmit={handleSubmit}>
                                <ol>
                                    {data && data.length > 0 && q[0].type === "question" && data && data.length > 0 && q[0].answers.length > 0 && q[0].answers.map((item, key) => <QuestionListItem key={item.id} keyval={item.id} Answer={item.answer} next={item.next} updateAnswer={updateAnswer} />)}
                                    {data && data.length > 0 && q[0].type === "finish" && data && data.length > 0 && q[0].answers.length > 0 && q[0].answers.map((item, key) => <QuestionListItem key={item.id} keyval={item.id} Answer={item.answer} next={item.next} updateAnswer={updateAnswer} />)}
                                    {data && data.length > 0 && q[0].type === "check" && data && data.length > 0 && q[0].answers.map((item, key) => <QuestionCheckItem key={item.id} keyval={item.id} Answer={item.answer} next={item.next} updateAnswer={updateAnswer} />)}
                                </ol>

                                <QuestionTools questionHistory={questionHistory} qid={qid.current} pqid={qid.previous} setQid={setQid} qtype={data && data.length > 0 && q[0].type} />
                            </form>
                        : <><br /><button onClick={ () => reset() } className="startbutton">Restart</button></>}

                    </div>

                </div>
                <div className='flex-item'>

                    {console.log("LENGTH", data.length)}
                    {data && data.length > 0 && q[0].additional_info !== '' && q[0].type !== "finish"  ? <div id="additional_info"><h3>Additional Information</h3><p dangerouslySetInnerHTML={{ __html: data && data.length > 0 && q[0].additional_info }}></p></div> : null}
                    {data && data.length > 0 && q[0].assets.length > 0 ? <div id="assets">
                        <h3>Assets</h3>
                        <p>These assets offer additional information to help inform your investigation of carbon farming opportunities.</p>
                        <ul>{data && data.length > 0 && q[0].assets.length > 0 && q[0].assets.map((item, key) => <li><a target="new" href={item.url}>{item.title}</a> <span className="externallink">External Link</span></li>)}</ul></div> : null}
                </div>
            </div>
            <div className="footer">
                <h4>Disclaimer</h4>
                <p>The information contained herein is general in nature and does not take into account your personal situation. This tool should be used for information gathering and clarification of options - just one step in understanding which carbon opportunity is appropriate for your business. You should consider seeking independent legal, financial, taxation or other advice to check how the information might relate to your personal situation. Trusted advisors such as accountants, lawyers, agronomists and financial advisors can offer expert advice on the option/s you are considering before decisions are made which could impact current and future generations of the enterprise.</p>
                <p>&copy; { new Date().getFullYear() } Australian Farm Institute</p>
            </div>
        </div></>
    );
}

export default Question;
