import '../css/QuestionCheckItem.css';

function QuestionCheckItem(props) {

    props.updateAnswer(props.next);

    if (props.keyval !== "") {
        return (
            <input key={ props.keyval } className="checkquestion" name="answer" id="answer" type="hidden" checked="checked" value={props.next} />
        );
    } else {
        return null;
    };
}

export default QuestionCheckItem;