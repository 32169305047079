import '../css/Welcome.css';
import { useCookies } from 'react-cookie';
import afi_logo from '../images/afi_logo.jpg';
import af_logo from '../images/af_logo.png';

function Welcome() {
     // eslint-disable-next-line
    const [cookies, removeCookie] = useCookies(['user']);

    const reset = () => {
        //removeCookie(['question_history'], { path:'/'});
        document.cookie = "question_history=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.location = "/question/1"
    };

    return (
        <><div className="logos">
            <img className="af-logo" alt="Agrifutures Logo" src={af_logo} />
            <img className="afi-logo" alt="Australian Farm Institute Logo"  src={afi_logo} />
            </div>
        <div className="welcome">
            <h1>Welcome to the Carbon Opportunity Decision Support Tool (CODST). </h1>
            <p>Carbon is an essential ingredient for agriculture, and reinvesting this back into farming systems is a win-win.</p>
            <p class="standfirst">This tool is designed to assist Australian land managers in better understanding carbon farming opportunities and to identify which might be best suited for their enterprise.</p>

            <div class="video"><iframe src="https://www.youtube.com/embed/UG8Ba0ckAV4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>

            <div class="welcome-content"><p>The tool covers the following opportunities for carbon farming by taking users through a decision-tree questionnaire:

            <ul><li>The Emission Reduction Fund </li>
                <li>Private carbon markets </li>
                <li>Sustainability linked loans </li>
                <li>Carbon neutral certification </li>
                <li>Productivity gains</li>
                </ul></p>

                <p>Rather than closing off pathways before providing adequate information, the tool asks users to 'choose their own adventure' and encourages consideration of the pros and cons of these options and their interconnection. You can backtrack or restart at any time.</p>

                <p>Given the complexity and frequency of change in the carbon space, the tool does not take every factor into detail. To be applicable across commodity types, geographical areas and business structures, it is by design general in nature.</p>

                <p>To help make sense of this complex environment, these questions focus on business situation, future plans, risk appetite and attitudes rather than the specific management practices or financial performance of an enterprise. </p>

                <p>While the tool is being regularly reviewed and updated, users should be aware that some emerging opportunities may not yet be covered.</p>

                <p>The CODST has been developed by the Australian Farm Institute (AFI) with funding from AgriFutures Australia. Please send any feedback on the tool to the AFI at <a href="mailto://info@farminstitute.org.au">info@farminstitute.org.au</a></p>

                <h4>Summaries and Guides:</h4>
                <ul><li><a href="https://agrifutures.com.au/wp-content/uploads/2022/08/On-Farm-Carbon-Management-Handbook.pdf">A farmer's handbook to on-farm carbon management</a></li>
                <li><a href="/assets/CODST_Summary_ERF_Sept_2023.pdf">ERF PDF summary</a></li>
                <li><a href="/assets/CODST_Summary_carbon neutral certification_Sept 2023.pdf">Carbon Neutral Certification PDF summary</a></li>
                <li><a href="/assets/CODST_Summary_Sustainability Linked Loans_Sept_2023.pdf">Sustainability linked loan PDF summary</a></li>
                <li><a href="/assets/CODST_Summary_Private Markets_Sept_2023.pdf">Private market PDF summary</a></li>
                <li><a href="/assets/CODST_Summary_productivity gains_Sept_2023.pdf">Productivity gains PDF summary</a></li>
                  </ul>
            </div>
            {cookies.question_history ? <a href={'/question/' + cookies.question_history[cookies.question_history.length - 1]} className="startbutton">Continue</a> : <a href="/question/1" className="startbutton">Start the questionnaire</a>}
            {cookies.question_history && (
                <button onClick={ () => reset() } className="startbutton">Restart</button>
            )}

            <div id="additional_info">
            <h3>DISCLAIMER:</h3>
            <p>The information contained herein is general in nature and does not take into account your personal situation. This tool should be used for information gathering and clarification of options - just one step in understanding which carbon opportunity is appropriate for your business. You should consider seeking independent legal, financial, taxation or other advice to check how the information might relate to your personal situation. Trusted advisors such as accountants, lawyers, agronomists and financial advisors can offer expert advice on the option/s you are considering before decisions are made which could impact current and future generations of the enterprise.</p>
            </div>
        </div>
        </>
  );
}

export default Welcome;
