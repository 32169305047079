import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Question from './components/Question';
import Welcome from './components/Welcome';

export default function App() {
  return (
    <div className="App">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/question/:question" element={<Question />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
