import '../css/QuestionListItem.css';

function QuestionListItem(props) {

    if (props.keyval !== "") {
        return (
            <li key={props.keyval}>
                <input name="answer" id={"answer"+props.keyval} type="radio" value={props.next} onChange={e => props.updateAnswer(e.target.value)} />
                <label htmlFor={"answer"+props.keyval}>{props.Answer}</label>
            </li>
        );
    } else {
        return null;
    };
}

export default QuestionListItem;