import '../css/QuestionTools.css';
import {
  useNavigate,
  Link
} from "react-router-dom";
import { useCookies } from 'react-cookie';

function QuestionTools(props) {

  const navigate = useNavigate();
   // eslint-disable-next-line
  const [cookie, setCookie] = useCookies(['user'])

  const handleBack = () => {
    if (props.questionHistory.length > 1) {
      console.log("Back" + props.pqid)
      //history.push('/' + props.pqid)
      if(props.questionHistory.at(-1) === 'undefined' || props.questionHistory.at(-2) === 'undefined') {
        props.setQid({ "current": 1, "previous": 1 })
        props.questionHistory.pop()
        navigate('/question/1');
      } else {
        props.setQid({ "current": props.questionHistory.at(-1), "previous": props.questionHistory.at(-2) })
        props.questionHistory.pop()
        setCookie('question_history', props.questionHistory, { path: '/' })
        navigate('/question/'+props.questionHistory.at(-1));
      }
    } else {
      props.setQid({ "current": 1, "previous": 1 })
      props.questionHistory.pop()
      navigate('/question/1');
    }
  }

  return (
    <div className="question_tools">
      {props.questionHistory.length >= 1 && <button type="button" onClick={handleBack}>Back</button>}
      {props.qtype === "check" ? <button type="submit">Next</button> : <button type="submit">Next</button> // no longer needed but keeping just in case 
      }
      <p><Link to="/">or return to the home page...</Link></p>
    </div>
  );
}

export default QuestionTools;